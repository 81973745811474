import React from "react"
import Link from "gatsby-link"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import { graphql } from "gatsby"

class SubPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description
    const blogs = data.allContentfulPosts.edges
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO
          title="Blogs"
          keywords={["blog", "gatsby", "javascript", "react"]}
        />
        <div>
          <h1>Blogs</h1>
          <div className="postListing">
            {blogs.map(({ node }) => {
              const title = node.title || node.slug
              const url = "/blog/" + node.slug
              return (
                <div key={node.slug} className="blog">
                  <Img fixed={node.featuredImage.fixed} />
                  <div className="content">
                    <h3
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      <Link style={{ boxShadow: "none" }} to={url}>
                        {title}
                      </Link>
                    </h3>
                    <small>{node.date}</small>
                    <p
                      style={{
                        fontSize: rhythm(1 / 2),
                      }}
                      dangerouslySetInnerHTML={{
                        __html: node.body.childMarkdownRemark.excerpt,
                      }}
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default SubPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allContentfulPosts(
      sort: { fields: [date], order: DESC }
      filter: { type: { eq: "blog" } }
    ) {
      edges {
        node {
          id
          title
          slug
          date(formatString: "MMMM Do, YYYY")
          featuredImage {
            fixed(width: 300) {
              src
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 250)
            }
          }
        }
      }
    }
  }
`
